.language-disclaimer-container {
  max-width: 1440px;
}
.language-disclaimer {
  font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #193946;
  line-height: 22px;
  margin: 15px 38px 0px;
  font-style: italic;
}
.ctn-voter-guid {
  margin: 30px 0px;
  text-align: center;

  span.bold {
    font-weight: bold;
  }

  .title {
    font-size: 175px;
    line-height: 148.75px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    &.language-customization-kr {
      font-size: 159px;
    }
  }

  .sub-title {
    font-size: 135px;
    line-height: 114.75px;
    text-align: left;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: -0.02em;
    &.language-customization-kr {
      font-size: 130px;
      margin: 40px 0px;
      line-height: 130px;
    }
  }
  @media (max-width: 1200px) {
    .title {
      font-size: 140px;
      line-height: 120px;
      &.language-customization-kr {
        font-size: 120px;
        line-height: 100px;
      }
    }
    .sub-title {
      font-size: 100px;
      line-height: 85px;
      &.language-customization-kr {
        font-size: 100px;
        line-height: 100px;
      }
    }
  }
  p {
    text-align: left;
    margin-top: 20px;
  }
  .leader {
    font-size: 25px;
    text-align: left;
    line-height: 30px;
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .image-ballot-center {
    align-self: center;
  }
  .image-ballot-top-minus-5percent {
    align-self: flex-start;
    margin-top: -5%;
  }
  .image-ballot {
    width: 100%;
  }
  .stepone {
    .ctn-form {
    }
  }
  .steptwo {
    .btn-send-email {
      background: #fff !important;
      color: #000 !important;
      border: 2px solid #ec704c !important;
      padding: 4px 15px !important;
    }
  }
  .stepfour {
    display: inline-block;
    .ctn-issues {
      margin-top: 0;
      max-width: 200px;
      .ctn-issues-title {
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
        text-transform: uppercase;
        text-align: left;
        float: left;
      }
      .ctn-issues-select {
        text-transform: uppercase;
        position: relative;
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
        float: left;
        margin: -7px 10px 0px 10px;
        text-align: left;
        color: #212529;
        cursor: inherit;
        border: 3px solid #ec704c;
      }
      .ctn-issues-selected.none-border {
        border-bottom: none;
      }
      a.button-submit.btn {
        background-color: transparent;
        border: 3px solid #ec704c;
        box-sizing: border-box;
        color: #193946;
      }
      a.button-submit.btn:hover {
        background-color: #ec704c;
      }
      img.icon-drop-down {
        width: 20px;
      }
      .ctn-issues-dropdown {
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
        z-index: 10;
        position: absolute;
        border: 3px solid #212529;
        border-width: 0px 3px 3px 3px;
        background: #fff;
        width: 350px;
        height: 150px;
        overflow-y: scroll;
        padding: 2px 10px;
        padding-right: 30px;
        width: 100%;
        cursor: inherit;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border: 3px solid #193946;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }
  .ctn-form {
    // padding: 0px 10%;

    .form-submit {
      margin: 0px 0px 20px 0px;
      width: 100%;

      .userfield-input {
        border: 2px solid #000;
        max-width: 55%;
        background: #fff;
        margin-right: 10px;
        border-radius: 0px !important;
        height: 45px;
        font-weight: normal;
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 16%;
        }
      }
      .btn-send-email {
        margin-left: 10px;
        color: #fff;
        border: none;
        background: transparent;
        background: #ec704c;
        line-height: 30px;
        padding: 2px 5px 2px 12px;
        font-size: 30px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        text-transform: uppercase;
        font-family: "garage-gothic", sans-serif;

        img {
          width: 22px;
          margin: 0px 5px 5px 10px;
        }
      }
    }
    .phone_note {
      text-align: left;
      font-size: 14px;
      a {
        color: #17bebb;
      }
    }
    .ballot-start-over {
      align-self: center;
      margin-left: 15px;
      margin-right: 5px;
      font-size: 30px;
      cursor: inherit;
    }
  }
  .v-mobile {
    display: none;
  }
  .v-desktop {
    display: block;
  }
  .user-checked {
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    letter-spacing: -0.02em;
    text-align: left;
    position: relative;
    cursor: inherit;
    display: flex;
    flex-direction: row-reverse;
    img {
      margin-right: 10px;
    }

    .icon-user-unchecked {
      z-index: 10;
    }

    .icon-user-checked {
      position: absolute;
      z-index: 1;
      top: -8px;
      left: 1px;
    }
  }
}
@media (max-width: 991px) {
  .search-candidate-mobile {
    display: none;
  }
  .ctn-voter-guid {
    .v-mobile {
      display: block;
      padding: 0px 15px;
      margin-top: 10px !important;
    }
    .v-desktop {
      display: none;
    }

    .button-submit {
      // margin-left: -20px;
      img {
        width: 30px;
        margin: 5px;
      }
    }
    margin-top: 10px;
    .stepthree {
      .leader {
        margin-top: 5px;
      }
    }
    .title {
      font-size: 100px;
      line-height: 80px;
      margin-top: 30px;
      &.language-customization-kr {
        font-size: 70px;
      }
    }
    .sub-title {
      font-size: 85px;
      line-height: 70px;
      &.language-customization-kr {
        font-size: 55px;
        line-height: 55px;
      }
    }
    .leader {
      font-size: 20px;
      line-height: 20px;
      margin-top: 20px;
    }
    .ctn-form {
      .form-submit {
        .btn-send-email {
          font-size: 25px;
          float: left;
          margin-left: 0px;
          background: #fffaf7 !important;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .search-candidate-mobile {
    display: none;
  }
  .language-disclaimer {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    margin: 15px 38px 0px;
    // padding: 0px 15px;
  }
  .ctn-voter-guid {
    .v-mobile {
      display: block;
      padding: 0px 15px;
      margin-top: 10px !important;
    }
    .v-desktop {
      display: none;
    }

    .button-submit {
      // margin-left: -20px;
      img {
        width: 30px;
        margin: 0px 5px 5px 0px;
      }
    }
    margin-top: 10px;
    .title-large {
      font-size: 120px !important;
      line-height: 0.85 !important;
    }
    .title {
      font-size: 70px;
      line-height: 65px;
      margin-top: 5px;
      &.language-customization-kr {
        font-size: 40px;
        line-height: 30px;
      }
    }
    .sub-title-large {
      font-size: 100px !important;
      line-height: 0.85 !important;
    }
    .sub-title {
      font-size: 60px;
      line-height: 50px;
      &.language-customization-kr {
        font-size: 43px;
      }
    }
    .leader {
      font-size: 15px;
      line-height: 18px;
      margin-top: 20px;
    }
    .ctn-form {
      .form-submit {
        display: inline-block !important;
        margin-bottom: 0;
        .address-input {
          font-size: 20px;
          padding: 7px 10px;
        }
        .autocomplete-dropdown-container {
          span {
            font-size: 15px;
          }
        }
      }
    }
  }

  .ctn-form {
    .userfield-input {
      max-width: 100% !important;
      margin: 20px 0px;
      &:nth-child(1) {
        width: 100% !important;
      }
      &:nth-child(2) {
        width: 100% !important;
      }
      &:nth-child(3) {
        width: 100% !important;
      }
      &:nth-child(4) {
        width: 100% !important;
      }
    }
    .candidate-sendmail-mobile {
      .userfield-input {
        background: #fffaf7 !important;
        margin: 10px 0px;
        font-weight: bold !important;
        text-transform: uppercase;
        &::placeholder {
          font-weight: bold !important;
          text-transform: uppercase;
          color: #000;
        }
        &:-ms-input-placeholder {
          font-weight: bold !important;
          text-transform: uppercase;
          color: #000;
        }
        &::-ms-input-placeholder {
          font-weight: bold !important;
          text-transform: uppercase;
          color: #000;
        }
      }
      .userfield-input:nth-child(1) {
        float: left;
        width: 48% !important;
      }
      .userfield-input:nth-child(2) {
        float: right;
        width: 48% !important;
        margin-right: 0px;
      }
    }
  }
  .ctn-issues {
    padding: 0px !important;
    margin-top: 20px !important;
    .ctn-issues-select {
      width: 100% !important;
      margin: 0px !important;
      .ctn-issues-selected {
        width: 100% !important;
      }
    }
  }
  .ctn-voter-guid .stepfour .ctn-issues a.button-submit.btn {
    margin-left: 0;
    font-size: 25px;
    line-height: 21px;
    display: flex;
    justify-content: space-evenly;
    max-width: 140px;
    align-items: center;
  }
}
@media (max-width: 575px) {
  .language-disclaimer {
    margin: 15px 20px 0px;
    // padding: 0px 15px;
  }
  .ctn-voter-guid {
    .hero-mobile-float {
      padding: 0px 15px;
      float: right;
      width: 42%;
      text-align: right;
    }

    .title-large {
      font-size: 78px !important;
      line-height: 0.82 !important;
    }
    .title {
      font-size: 50px;
      line-height: 42.5px;
      letter-spacing: -0.02em;
      &.language-customization-kr {
        font-size: 50px;
      }
    }
    .sub-title-large {
      font-size: 65px !important;
      line-height: 0.82 !important;
    }
    .sub-title {
      font-size: 52px;
      line-height: 45px;
      letter-spacing: -0.02em;
      &.language-customization-kr {
        margin: 40px 0px;
        font-size: 50px;
        line-height: 50px;
      }
    }
  }
}
