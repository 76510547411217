.calendar-section {
  width: 95%;
  padding-bottom: 20px;
  .title {
    font-family: "garage-gothic", sans-serif;
    font-weight: 700;
    font-size: 80px;
    line-height: 68px;
    margin: 40px auto;
    border-bottom: 5px solid #17bebb;
    padding-bottom: 30px;
    text-align: center;
    width: 100%;
  }
  .peach-color {
    border-bottom: 5px solid #ec704c;
  }
  .leader {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 27.5px;
    flex-grow: 2;
    max-width: 70%;
    margin: 0px auto 10px;
    a {
      text-decoration: underline;
      color: rgba(25, 57, 70, 1);
    }
    p {
      text-align: center;
    }
  }
  .ctn-text-content-note {
    background: rgba(235, 165, 109, 0.25);
    padding: 20px 0px;

    .text-content-note {
      margin: 0px 30px !important;
      // width: 100%;
      border-bottom: 4px solid #fffaf7;
      min-height: 66px;
      // text-align: center;
      justify-content: center;
      // display: inline-block;
      padding: 8px 8px;
    }
    .text-content-note-step {
      font-weight: 700;
      font-size: 40px;
      line-height: 34px;
      text-transform: uppercase;
      font-family: "garage-gothic", sans-serif;
      // text-align: center;
      vertical-align: middle;
      letter-spacing: -0.02em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 12px 0px;
      span.language-customization-es {
        font-size: 31px;
      }
    }
    .icon-hand-orange {
      max-width: 45px;
      width: 100%;
      height: auto;
      max-height: 30px;
    }
    .col-hand-orange {
      max-width: 55px;
      justify-content: center;
      align-items: center;
      display: flex;
      object-fit: contain;
    }
    .text-content-note-t {
      float: right;
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-weight: 700;
      font-size: 25px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      letter-spacing: -0.02em;
      text-align: right;
      // border-left: 4px solid #fffaf7;
      // padding: 5px;
      a {
        font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-style: normal;
        color: #193946;
        text-decoration: underline;
      }
      a:hover {
        text-decoration: none;
        color: #193946;
      }
      p {
        font-size: inherit;
        line-height: inherit;
        margin: 0px;
      }
    }

    span.line {
      display: inline-block;
    }

    hr.calendar-hr {
      border-top: 4px solid white;
      margin: 0px 0px;
    }
  }
  .calendar-grid {
    padding: 0px 4%;
  }
  .col-right {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
.div-warning {
  margin: 10px 0 0;
  max-width: 100%;
}
@media (max-width: 768px) {
  .calendar-section {
    .title {
      font-size: 40px;
      line-height: 34px;
      margin: 20px 0;
      width: 100%;
      border-bottom: 0;
      padding-bottom: 0;
    }
    .ctn-text-content-note {
      padding: 10px 0px;
      .icon-hand-orange {
        height: 15px;
        max-width: 23px;
        width: 23px;
      }
      .text-content-note {
        margin: 0px 12px !important;
        min-height: 35px;
        border-bottom: 2px solid #fffaf7;
        padding: 8px 0px;
      }
      .text-content-note-t {
        font-size: 12.5px;
        span.language-customization-es {
          font-size: 11px;
        }
      }
      .text-content-note-step {
        font-size: 20px;
        line-height: 18px;
        padding: 0px 3px 0px 0px;
        span.language-customization-es {
          font-size: 14px;
        }
      }
    }
  }
}
